import React, { useEffect, useState, useRef } from "react";
import { graphql } from 'gatsby';
import styled from "styled-components";
import Main from '../sections/Main/Main';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo/Seo';

// markup
const IndexPage = ({data}) => {
  if (!data) return null

  const document = data.allPrismicMyrchBlock;

  return (
    <Layout>
      <Seo title=""/>
      <Main document={document} /> 
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allPrismicMyrchBlock(sort: {order: DESC, fields: first_publication_date}) {
      edges {
        node {
          uid
          data {
            image {
              alt
              copyright
              url
              thumbnails
              dimensions {
                height
                width
              }
            }
            image_hover {
              alt
              copyright
              url
              thumbnails
            }
            logo {
              alt
              copyright
              url
              thumbnails
            }
            product_text
            url {
              url
            }
            category_text
          }
        }
      }
    }
  }
`

export default IndexPage
